var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Dealers"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","exact":"","to":{ name: 'dealers-account-maintenance.dealers.create' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-plus-outline")]),_c('span',{domProps:{"textContent":_vm._s('Add new')}})],1)]},proxy:true}])}),_c('v-card',[_c('toolbar-menu',{ref:"toolbar",attrs:{"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"options":_vm.options,"headers":_vm.dealers.headers,"items":_vm.dealers.data,"color":"primary","item-key":"id","loading":_vm.loading,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'dealers-account-maintenance.dealers.edit', params: { id: item.id } },"text":"","color":"primary","icon":""}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('h3',[_c('code',[_vm._v(_vm._s(item.code))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" active ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" inactive ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                    name: 'dealers-account-maintenance.dealerusers.index',
                    params: { dealer_id: item.id },
                  },"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-group")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('View Dealer Users')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                    name: 'dealers-account-maintenance.dealers.salesconsultants',
                    params: { id: item.id },
                  },"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-supervisor")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('View Sales Consultants')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                    name: 'dealers-account-maintenance.dealers.edit',
                    params: { id: item.id },
                  },"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.handleDeleteDealer(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Delete')}})])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }