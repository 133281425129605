import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=79f32fd4&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VChip,VDataTable,VIcon,VTooltip})
